import { shape, arrayOf, object, string, number } from 'prop-types';
import Pagination from '@/client/types/Pagination';

const SearchRequest = shape({
  originalSearchRequest: object, // this is actually of type SearchRequest but we cannot have circular prop-types
  l1Category: shape({ id: number }),
  l2Category: shape({ id: number }),
  pagination: Pagination,
  attributesById: arrayOf(number),
  attributesByKey: arrayOf(object),
  attributeRanges: arrayOf(object),
  searchQuery: string,
});

export default SearchRequest;
