import PropTypes from 'prop-types';
import Location from '@/client/types/Location';
import SellerInformation from '@/client/types/SellerInformation';
import OtherSellerListings from '@/client/types/OtherSellerListings';

export const ListingShape = {
  title: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  vipUrl: PropTypes.string.isRequired,
  priceInfo: PropTypes.shape({
    priceCents: PropTypes.number.isRequired,
    priceType: PropTypes.string.isRequired,
  }).isRequired,
  location: Location.isRequired,
  sellerInformation: SellerInformation.isRequired,
  imageUrls: PropTypes.arrayOf(PropTypes.string),
  pictures: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string.isRequired, sizes: PropTypes.object })),
  otherSellerListings: OtherSellerListings,
  date: PropTypes.string.isRequired,
  pageLocation: PropTypes.string,
  priorityProduct: PropTypes.string,
  attributes: PropTypes.array,
  napAvailable: PropTypes.bool,
  opvalStickerText: PropTypes.string,
  trustIndicators: PropTypes.array,
  urgencyFeatureActive: PropTypes.bool,
  thinContent: PropTypes.bool,
};

export default PropTypes.shape(ListingShape);
