import React, { memo } from 'react';
import isEqual from 'lodash.isequal';
import { Header } from '@hz-design-system/web-ui';

const HeaderJSX = (props) => <Header {...props} />;

function arePropsEqual(prevProps, nextProps) {
  return isEqual(prevProps, nextProps);
}

export default memo(HeaderJSX, arePropsEqual);
