import { shape } from 'prop-types';
import { ListingShape } from '@/client/types/Listing';

const listingWithoutVipUrl = { ...ListingShape };
delete listingWithoutVipUrl.vipUrl;

const BottomBlockListing = shape({
  ...listingWithoutVipUrl,
});

export default BottomBlockListing;
