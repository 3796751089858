import PropTypes from 'prop-types';

export default PropTypes.shape({
  totalSellerListingsResults: PropTypes.number.isRequired,
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      vipUrl: PropTypes.string.isRequired,
      title: PropTypes.string,
      imageUrl: PropTypes.string,
      priceInfo: PropTypes.shape({
        priceCents: PropTypes.number,
        priceType: PropTypes.string,
      }),
      thinContent: PropTypes.bool,
    }),
  ),
});
