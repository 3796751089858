import React from 'react';
import { bool, number, arrayOf, node, shape, string } from 'prop-types';
import * as types from '@/client/types';
import AlternativeLocaleType from '@/client/types/AlternativeLocaleType';
import Header from '@/client/components/Header';

const AppShell = ({
  seller,
  searchRequest,
  attributeHierarchy,
  seoFriendlyAttributes,
  headerProps,
  searchCategory,
  searchCategoryOptions,
  children,
  reloadPageOnSearch = false,
}) => {
  const searchRequestObject = {
    ...searchRequest,
    seller,
    seoFriendlyAttributes,
    attributes: attributeHierarchy, // this is from facets, overwriting the one from searchRequest
  };

  return (
    <>
      <Header
        {...headerProps}
        searchRequestObject={searchRequestObject}
        searchCategoryId={searchCategory}
        searchCategoryOptions={searchCategoryOptions}
        reloadPageOnSearch={reloadPageOnSearch}
      />
      {children}
    </>
  );
};

AppShell.propTypes = {
  children: node.isRequired,
  seller: types.Seller,
  categories: types.Categories,
  searchRequest: types.SearchRequest,
  attributeHierarchy: types.AttributeHierarchy,
  searchCategory: number.isRequired,
  searchCategoryOptions: arrayOf(types.Category).isRequired,
  seoFriendlyAttributes: arrayOf(string),
  alternativeLocales: arrayOf(AlternativeLocaleType),
  headerProps: shape({
    searchQuery: string,
    loginTargetUrl: string, // and a lot more details
  }).isRequired,
  reloadPageOnSearch: bool,
};

export default AppShell;
