import { shape, bool, string, number, object } from 'prop-types';

export default shape({
  active: bool.isRequired,
  id: number.isRequired,
  isSavedSeller: bool.isRequired,
  location: string,
  name: string.isRequired,
  periodSinceRegistrationDate: string.isRequired,
  reviews: object,
  showBanners: bool.isRequired,
  title: string.isRequired,
});
