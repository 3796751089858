import PropTypes from 'prop-types';

export default PropTypes.shape({
  locale: PropTypes.string.isRequired,
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    parentName: PropTypes.string,
  }),
  selectedAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      attributeKey: PropTypes.string.isRequired,
      attributeValueKey: PropTypes.string.isRequired,
      attributeValueLabel: PropTypes.string.isRequired,
    }),
  ),
});
