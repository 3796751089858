import PropTypes from 'prop-types';
import HubPage from '@/client/types/HubPage';
import Listing from '@/client/types/Listing';
import SearchRequest from '@/client/types/SearchRequest';
import SeoInformation from '@/client/types/SeoInformation';
import Facet from '@/client/types/Facet';
import SortOptions from '@/client/types/SortOptions';
import { Categories, Category } from '@/client/types/Categories';
import AttributeHierarchy from '@/client/types/AttributeHierarchy';
import BottomBlockListing from '@/client/types/BottomBlockListing';
import AlternativeLocaleType from '@/client/types/AlternativeLocaleType';

const SearchResultsType = PropTypes.shape({
  searchRequest: SearchRequest.isRequired,
  originalQuery: PropTypes.string,
  correctedQuery: PropTypes.string,
  listings: PropTypes.arrayOf(Listing),
  topBlock: PropTypes.arrayOf(Listing),
  bottomBlock: PropTypes.arrayOf(BottomBlockListing),
  facets: PropTypes.arrayOf(Facet),
  sortOptions: SortOptions,
  totalResultCount: PropTypes.number,
  attributeHierarchy: AttributeHierarchy,
  isSearchSaved: PropTypes.bool,
  metaTags: SeoInformation,
  correlationId: PropTypes.string,
  categoriesById: Categories.isRequired,
  searchCategory: PropTypes.number.isRequired,
  searchCategoryOptions: PropTypes.arrayOf(Category).isRequired,
  seoFriendlyAttributes: PropTypes.arrayOf(PropTypes.string),
  alternativeLocales: PropTypes.arrayOf(AlternativeLocaleType),
  hubPage: HubPage,
});

export default SearchResultsType;
