import Router from 'next/router';

/**
 * Navigates to the same page but with different query params
 * @see https://nextjs.org/docs#intercepting-popstate
 *
 * Required for client-side routing instead of full page refresh
 */
const pushToRouter = ({ query, href, pathname = Router.pathname, options = { shallow: true } }) =>
  Router.push({ pathname, query }, href, { ...options });

export default pushToRouter;
