import { shape, string, arrayOf } from 'prop-types';

const AttributeHierarchyElement = shape({
  attributeValueId: string,
  attributeValueLabel: string,
  attributeValueKey: string,
});
const AttributeHierarchy = shape({
  [string]: arrayOf(AttributeHierarchyElement),
});

export default AttributeHierarchy;
