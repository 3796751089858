import queryString from 'query-string';
import { seoUrlPart } from '@ecg-marktplaats/aurora-js-formatters';
import md5 from 'blueimp-md5';
import urlPrefixes from '@/constants/urlPrefixes';
import { defaults as specialAttributesDefaults, attributeKeys } from '@/constants/attributes/nonStandards';
import sortAttributeNames from '@/constants/attributes/sort';
import { maxSeoAttributes } from '@/constants/attributes/standards';
import formatSearchQueryFromUrl from '@/utils/formatters/formatForURL';
import urlBuilderSecret from '@/constants/urlBuilderSecret';
import SEO from '@/constants/seo';

const hashPageName = ({ name, secret }) => md5(`${name}{${secret}}`, false);
const isValidString = (key) => typeof key === 'string' && key !== '' && key !== null;
const includeViewOptions = (view, isViewOptionsClicked) =>
  isValidString(view) && (isViewOptionsClicked || view === 'gallery-view');
const isValidPageSize = (pageSize) => pageSize && Number(pageSize) > 0; // disable default pageSize
const createURLComponent = (urlPart) => seoUrlPart(urlPart).toLowerCase();
const isNotEmpty = (arr) => arr && Array.isArray(arr) && arr.length > 0;

/**
 *
 * @param {object} attributes : expected param is mergedAttributes for link Generation
 * @param {Array} attributesOrder
 * @param {object} textAttributesOrder
 * @param {boolean} withAllAttributes
 * @returns {{seoFriendlyAttributes: {}, hashFriendlyAttributes: {}, seoFriendlyTextAttributes: {}}}
 * seoFriendlyAttributes: (max item 2), (has always first element of seoFriendly attributes)
 * hashFriendlyAttributes: (all hash friendly attributes) + (rest of the seo Friendly attributes)
 * seoFriendlyTextAttributes: (max 1 item), (has always first element of seoFriendly text attributes)
 */
const getSortedAttributes = (attributes, attributesOrder = [], textAttributesOrder = {}, withAllAttributes) => {
  const seoFriendlyAttributes = [];
  const hashFriendlyAttributes = {};
  const seoFriendlyTextAttributes = {};
  const seoFriendlyTextAttributesRequestObject = {};

  const pushValueKey = (attributeKey, attributeValue) => {
    if (Object.values(attributeKeys).indexOf(attributeKey) > -1) {
      if (hashFriendlyAttributes[attributeKey]) {
        hashFriendlyAttributes[attributeKey].push(encodeURIComponent(attributeValue.attributeValueKey));
      } else {
        hashFriendlyAttributes[attributeKey] = [encodeURIComponent(attributeValue.attributeValueKey)];
      }
    } else {
      if (hashFriendlyAttributes[urlPrefixes.textAttributes]) {
        if (hashFriendlyAttributes[urlPrefixes.textAttributes][attributeKey]) {
          hashFriendlyAttributes[urlPrefixes.textAttributes][attributeKey].push(
            encodeURIComponent(attributeValue.attributeValueKey),
          );
        } else {
          hashFriendlyAttributes[urlPrefixes.textAttributes][attributeKey] = [
            encodeURIComponent(attributeValue.attributeValueKey),
          ];
        }
      } else {
        hashFriendlyAttributes[urlPrefixes.textAttributes] = {};
        hashFriendlyAttributes[urlPrefixes.textAttributes][attributeKey] = [
          encodeURIComponent(attributeValue.attributeValueKey),
        ];
      }
    }
  };

  const pushValueId = (attributeValue) => {
    const attributeKey = urlPrefixes.attributes;
    if (hashFriendlyAttributes[attributeKey]) {
      hashFriendlyAttributes[attributeKey].push(attributeValue.attributeValueId);
    } else {
      hashFriendlyAttributes[attributeKey] = [attributeValue.attributeValueId];
    }
  };

  const seoFriendlyKeys = Object.keys(attributes).map((key) => attributesOrder.indexOf(key) > -1 && key);

  const textAttributeOrderKeys = Object.keys(textAttributesOrder);
  const seoFriendlyTextAttributeKeys = Object.keys(attributes).filter(
    (key) => textAttributeOrderKeys.indexOf(key) > -1,
  );

  Object.keys(attributes)
    .sort((a, b) => attributesOrder.indexOf(a) - attributesOrder.indexOf(b))
    .filter((key) => attributes[key].length)
    .forEach((key) => {
      if (withAllAttributes) {
        if (seoFriendlyTextAttributeKeys.indexOf(key) > -1 && Object.keys(seoFriendlyTextAttributes).length < 1) {
          seoFriendlyTextAttributes[textAttributesOrder[key]] = attributes[key][0];
          seoFriendlyTextAttributesRequestObject[key] = attributes[key][0];
          attributes[key].slice(1, attributes[key].length).forEach((value) => pushValueKey(key, value));
        } else {
          attributes[key].forEach((attr) => {
            if (attr.attributeValueId) {
              seoFriendlyAttributes.push(attr);
            } else if (!attr.isDefault || key === attributeKeys.LANGUAGE) {
              seoFriendlyTextAttributes[key] = attributes[key][0];
              seoFriendlyTextAttributesRequestObject[key] = attributes[key][0];
              attributes[key].slice(1, attributes[key].length).forEach((value) => pushValueKey(key, value));
            }
          });
        }
        return;
      }

      if (seoFriendlyKeys.indexOf(key) > -1 && Object.keys(seoFriendlyAttributes).length < maxSeoAttributes) {
        seoFriendlyAttributes.push(attributes[key][0]);
        attributes[key].slice(1, attributes[key].length).forEach(pushValueId);
      } else if (attributes[key][0].attributeValueId) {
        attributes[key].forEach(pushValueId);
      } else if (specialAttributesDefaults.attributes.indexOf(attributes[key][0].attributeValueKey) === -1) {
        if (seoFriendlyTextAttributeKeys.indexOf(key) > -1 && Object.keys(seoFriendlyTextAttributes).length < 1) {
          seoFriendlyTextAttributes[textAttributesOrder[key]] = attributes[key][0];
          seoFriendlyTextAttributesRequestObject[key] = attributes[key][0];
          attributes[key].slice(1, attributes[key].length).forEach((value) => pushValueKey(key, value));
        } else {
          attributes[key].forEach((value) => pushValueKey(key, value));
        }
      }
    });

  return {
    seoFriendlyAttributes,
    hashFriendlyAttributes,
    seoFriendlyTextAttributes,
    seoFriendlyTextAttributesRequestObject,
  };
};

const getPrioritizedAttributes = (attributes, attributesOrder, textAttributesOrder, withAllAttributes) =>
  getSortedAttributes(attributes, attributesOrder, textAttributesOrder, withAllAttributes);

/**
 * create categories part for link generator
 * expected output: l/${l1-cat-name} || l/${l1-cat-name}/${l2-cat-name}
 * @param {{l1Category?: object, l2Category?: object}} categories object
 * @returns {string}
 */

const createCategoriesPart = ({ l1Category, l2Category } = {}) => {
  const isL1CategoryValid = l1Category && isValidString(l1Category.key);
  const isL2CategoryValid = l2Category && isValidString(l2Category.key);
  const isValid = isL1CategoryValid || isL2CategoryValid;

  let part = isValid ? `/${urlPrefixes.baseCategoriesURL}` : '';
  part += isL1CategoryValid ? `/${l1Category.key}` : '';
  part += isL2CategoryValid ? `/${l2Category.key}` : '';

  return part;
};

/**
 * create query part if exist
 * expected output /q/${searchQuery}
 */
const createQueryPart = (query = '') =>
  isValidString(query) ? `/${urlPrefixes.query}/${formatSearchQueryFromUrl(query)}` : '';

/**
 * create attributes part (SEO friendly part)
 * expected output: /f/${attrValueLabel1}+${attrValueLabel2}/${attrValueId1}+${attrValueId2}
 * p.s. must include max 2 attributes (for more details check: getSortedAttributes function)
 * includes Alphabetically order logic
 */
const createAttributesPart = (attributes = []) => {
  if (Array.isArray(attributes) && attributes.length) {
    const labels = attributes
      .map((attr) => createURLComponent(attr.attributeValueLabel))
      .join(urlPrefixes.seoFriendlyMergeKey);
    const ids = attributes.map((attr) => attr.attributeValueId).join(urlPrefixes.seoFriendlyMergeKey);
    return `/${urlPrefixes.attributes}/${labels}/${ids}`;
  }
  return '';
};

/**
 * create seo friendly text attributes part
 * @param attributes
 * @returns {string}
 */
const createTextAttributesPart = (attributes = {}) => {
  const textAttributeKeys = Object.keys(attributes);
  if (!textAttributeKeys.length) {
    return '';
  }
  const keys = textAttributeKeys.join(urlPrefixes.seoFriendlyMergeKey);
  const labels = textAttributeKeys
    .map((key) =>
      [attributeKeys.LANGUAGE, attributeKeys.OFFERED_SINCE].includes(key)
        ? attributes[key].attributeValueKey
        : attributes[key].attributeValueLabel,
    )
    .join(urlPrefixes.seoFriendlyMergeKey);

  return `/${urlPrefixes.textAttributes}/${keys}/${labels}`;
};

const createSellerNameComponent = (sellerName) => {
  const normalized = createURLComponent(sellerName);
  return normalized || 'verkoper';
  // TODO: translations ^
};

/**
 * create seller part
 */
const createSellerPart = (seller) =>
  seller && Number(seller.id) > 0 && isValidString(seller.name)
    ? `/${urlPrefixes.seller}/${createSellerNameComponent(seller.name)}/${seller.id}`
    : '';

/**
 * seller url generator for listing items
 */
const sellerUrlGenerator = ({ id, name }) => `${createSellerPart({ id, name })}/`;

/**
 * create page index part
 */
const createPageIndexPart = (page) => (Number(page) > 1 ? `/${urlPrefixes.page}/${page}` : '');

/**
 * expected output: {key1}:{value1},{value2}|{key2}:{value3}
 */
const createHashElement = (hashedFriendlyAttributes = {}) => {
  const keys = Object.keys(hashedFriendlyAttributes);

  return keys
    .map((key) => {
      if (key === urlPrefixes.textAttributes) {
        const textAttributesHashedPart = Object.keys(hashedFriendlyAttributes['ta'])
          .map((textAttributeKey) => {
            const attrValues = hashedFriendlyAttributes['ta'][textAttributeKey];
            const values = Array.isArray(attrValues) ? attrValues.join(',') : attrValues;
            return `${textAttributeKey}=${values}`;
          })
          .join(urlPrefixes.textAttributesSeparator);
        return `${urlPrefixes.textAttributes}:${textAttributesHashedPart}`;
      }
      const attrValues = hashedFriendlyAttributes[key];
      const values = Array.isArray(attrValues) ? attrValues.join(',') : attrValues;
      return `${key}${urlPrefixes.nonTrackingDefiner}${values}`;
    })
    .join(urlPrefixes.nonTrackingSeparator);
};

/**
 * create sortOptions part of hash url
 */
const createSortOptionsPart = (sortOptions = {}) => {
  const { sortBy, sortOrder, sortAttribute } = sortOptions;
  const { SORT_BY, SORT_ORDER, SORT_ATTRIBUTE } = sortAttributeNames;

  return {
    ...(sortBy && { [SORT_BY]: sortBy }),
    ...(sortOrder && { [SORT_ORDER]: sortOrder }),
    ...(sortAttribute && { [SORT_ATTRIBUTE]: sortAttribute }),
  };
};

const getAttributeKeyValuePair = ({ from, to, attributeKey } = {}) => {
  const part = {};
  if (typeof from !== 'undefined' && from !== null) {
    part[`${attributeKey}From`] = from;
  }
  if (typeof to !== 'undefined' && to !== null) {
    part[`${attributeKey}To`] = to;
  }
  return part;
};

/**
 * create saved search attributes object can be used as part of the URL hash
 * @param {object} obj
 * @param {array} obj.attributesByKey as part of the original search request
 */
const createSavedSearchAttrs = ({ attributesByKey } = {}) => {
  const savedSearchAttrs = { [urlPrefixes.asSavedSearch]: 'true' };
  const offeredSince = attributesByKey && attributesByKey.find((attr) => attr.attributeKey === 'offeredSince');
  if (offeredSince) {
    savedSearchAttrs[urlPrefixes.offeredSince] = offeredSince.attributeValueKey;
  }
  return savedSearchAttrs;
};

/**
 * generate links according to ADR document: https://github.mpi-internal.com/bnl/vostok-adr/blob/master/adr-033-seo.md
 * @returns {string}
 */
const createLinks = ({
  searchRequestObject,
  seller,
  categories,
  query,
  searchInTitleAndDescription: tAndD,
  page,
  pageSize,
  sortOptions,
  rangeAttributes = [],
  distance = 0,
  postcode = '',
  view,
  removeAllFilters,
  removeAllAttributes,
  removeAllOtherFilters,
  allowSavedSearch,
  isViewOptionsClicked,
  bypassSpellingSuggestion,
  showQueryInSeoFriendlyPart,
  traits,
  disableRedirect,
  prioritizedAttributes,
}) => {
  const keepAttributes = !removeAllFilters && !removeAllAttributes;

  // before the hash
  const sellerUrl = createSellerPart(seller);
  const filteredCategories = removeAllOtherFilters ? { ...categories.l1Category } : categories;
  const categoriesUrl = !removeAllFilters ? createCategoriesPart(filteredCategories) : '';
  const attributesUrl = keepAttributes ? createAttributesPart(prioritizedAttributes.seoFriendlyAttributes) : '';
  const textAttributesUrl = keepAttributes
    ? createTextAttributesPart(prioritizedAttributes.seoFriendlyTextAttributes)
    : '';
  const queryUrl = showQueryInSeoFriendlyPart ? createQueryPart(query) : '';
  const pageUrl = createPageIndexPart(page);
  const disableRedirectUrl =
    showQueryInSeoFriendlyPart && isValidString(query) && disableRedirect ? `?${urlPrefixes.disableRedirect}` : '';

  // After the hash
  const savedSearch = allowSavedSearch && searchRequestObject && searchRequestObject.asSavedSearch;
  const sortOptionsUrl = createSortOptionsPart(sortOptions);
  const rangeAttributesUrl = rangeAttributes.map(getAttributeKeyValuePair);

  const hashedAttributesUrl = createHashElement({
    ...(!showQueryInSeoFriendlyPart &&
      isValidString(query) && {
        [urlPrefixes.query]: formatSearchQueryFromUrl(query),
      }),
    ...(keepAttributes && prioritizedAttributes.hashFriendlyAttributes),
    ...(keepAttributes && Object.assign({}, ...rangeAttributesUrl)),
    ...(keepAttributes && sortOptionsUrl),
    ...(!removeAllFilters && Number(distance) > 0 && { [urlPrefixes.distance]: distance }),
    ...(!removeAllFilters && isValidString(postcode) && { [urlPrefixes.postcode]: postcode.toUpperCase() }),
    ...(!removeAllFilters && tAndD && { [urlPrefixes.searchInTitleAndDescription]: true }),
    ...((!removeAllFilters || query) && includeViewOptions(view, isViewOptionsClicked) && { [urlPrefixes.view]: view }),
    ...(isValidPageSize(pageSize) && { [urlPrefixes.Limit]: pageSize }),
    ...(keepAttributes && savedSearch && createSavedSearchAttrs(searchRequestObject)),
    ...(bypassSpellingSuggestion && { [urlPrefixes.bypassSpellingSuggestion]: true }),
    ...(!removeAllFilters && isNotEmpty(traits) && { [urlPrefixes.traits]: traits }),
  });
  const hashedUrl = hashedAttributesUrl ? `${urlPrefixes.nonTrackingKey}${hashedAttributesUrl}` : '';

  return `${sellerUrl}${categoriesUrl}${queryUrl}${attributesUrl}${textAttributesUrl}${pageUrl}/${disableRedirectUrl}${hashedUrl}`;
};

/**
 * merge attributes from searchRequest with new attribute
 * @param {object} obj
 * @param {object} obj.attributes
 * @param {object} obj.newAttribute
 * @param {boolean} obj.isSingleSelect
 * @param {boolean} obj.isSelected
 * @returns {{}}
 */
const mergeAttributes = ({ attributes = {}, newAttribute = {}, isSingleSelect = false, isSelected = false }) => {
  // if there is nothing as new Attribute
  if (!newAttribute || typeof newAttribute !== 'object') {
    return attributes;
  }

  const mergedObject = { ...attributes };
  const keys = Object.keys(newAttribute);

  keys.forEach((key) => {
    mergedObject[key] = attributes[key] ? attributes[key].concat([newAttribute[key]]) : [newAttribute[key]];
  });

  if (isSingleSelect) {
    keys.forEach((key) => {
      mergedObject[key] = [newAttribute[key]];
    });

    return mergedObject;
  }

  if (isSelected) {
    keys.forEach((key) => {
      if (attributes[key]) {
        mergedObject[key] = attributes[key].filter(
          (item) => item.attributeValueKey !== newAttribute[key].attributeValueKey,
        );
      }
    });

    return mergedObject;
  }

  return mergedObject;
};

/**
 * adds tracking data to a vip url if available
 */
const enrichVipUrlWithTrackingData = ({ vipUrl, trackingData, pageLocation, correlationId = '' }) => {
  const { url, query } = queryString.parseUrl(vipUrl);
  let updated = false;

  if (trackingData) {
    query.casData = trackingData;
    updated = true;
  }

  if (pageLocation) {
    query.c = hashPageName({
      name: pageLocation,
      secret: urlBuilderSecret,
    });
    updated = true;
  }

  if (correlationId) {
    query.correlationId = correlationId;
    updated = true;
  }

  if (updated) {
    return `${url}?${queryString.stringify(query)}`;
  }

  return vipUrl;
};

const hasRegularHashFriendlyAttributes = (prioritizedAttributes) => {
  const hashFriendlyAttributeKeys = Object.keys(prioritizedAttributes.hashFriendlyAttributes);
  const regularHashFriendlyAttributes = hashFriendlyAttributeKeys.filter(
    (attributeKey) => !SEO.noMaskHashAttributes.has(attributeKey),
  );
  return !!regularHashFriendlyAttributes.length;
};

const hasRangeAttributes = (rangeAttributes) => !!rangeAttributes?.length;

/**
 * Determines if link should be masked from crawler for crawl optimization reasons
 */
const shouldMaskLinkFromCrawler = ({ seller, prioritizedAttributes, rangeAttributes }) => {
  return hasRegularHashFriendlyAttributes(prioritizedAttributes) || hasRangeAttributes(rangeAttributes) || !!seller;
};

export {
  createCategoriesPart,
  createQueryPart,
  createAttributesPart,
  createSellerPart,
  createLinks,
  mergeAttributes,
  getPrioritizedAttributes,
  sellerUrlGenerator,
  enrichVipUrlWithTrackingData,
  shouldMaskLinkFromCrawler,
};
