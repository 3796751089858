import { shape, string, arrayOf } from 'prop-types';

export default shape({
  abSwitches: shape({
    [string]: shape({
      group: string,
      tags: arrayOf(string),
    }),
  }),
  featureSwitches: arrayOf(string),
});
