import PropTypes from 'prop-types';

export default PropTypes.shape({
  isBuyerLocation: PropTypes.bool.isRequired,
  onCountryLevel: PropTypes.bool.isRequired,
  abroad: PropTypes.bool.isRequired,
  distanceMeters: PropTypes.number,
  cityName: PropTypes.string,
  countryName: PropTypes.string,
  countryAbbreviation: PropTypes.string,
});
