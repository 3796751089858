import { shape, string, number } from 'prop-types';

const category = {
  name: string.isRequired,
  fullName: string.isRequired,
  id: number.isRequired,
  parentName: string,
  parentId: number,
};

export const Categories = shape({
  [string]: shape(category),
});

export const Category = shape({
  ...category,
  children: Categories,
});
