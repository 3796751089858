import { shape, string, bool } from 'prop-types';
import { Categories } from '@/client/types/Categories';

export default shape({
  type: string,
  label: string.isRequired,
  key: string.isRequired,
  linkProps: shape({
    categories: Categories,
    removeAllFilters: bool,
    removeAllAttributes: bool,
    attributes: shape({
      [string]: string,
    }),
    isSelected: bool,
  }),
});
