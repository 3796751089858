import PropTypes from 'prop-types';

export default PropTypes.shape({
  sellerWebsiteUrl: PropTypes.string,
  sellerLogoUrl: PropTypes.string,
  sellerId: PropTypes.number.isRequired,
  sellerName: PropTypes.string.isRequired,
  showWebsiteUrl: PropTypes.bool.isRequired,
  isVerified: PropTypes.bool,
});
