import PropTypes from 'prop-types';

import SearchResultsType from '@/client/types/SearchResultsType';
import User from '@/client/types/User';

const LRPContainerType = {
  user: User,
  // eslint-disable-next-line react/forbid-prop-types
  sellerProfile: PropTypes.object, // @TODO: Change this once established
  headerProps: PropTypes.shape({
    searchQuery: PropTypes.string,
    loginTargetUrl: PropTypes.string, // and a lot more details
  }).isRequired,
  meta: PropTypes.shape({
    breadcrumbs: PropTypes.object,
    canonicalUrl: PropTypes.string,
    product: PropTypes.object,
  }),
  searchRequestAndResponse: SearchResultsType.isRequired,
  generationTimeMillis: PropTypes.number,
  hrefLangUrl: PropTypes.string,
};

export default LRPContainerType;
